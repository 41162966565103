<template>
  <b-card
    v-if="Object.keys(filter).length"
    class="blog-edit-wrapper"
  >

    <!-- form -->
    <b-form class="mt-2" @submit.prevent="save">
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Nazwa urządzenia"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="filter.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Numer seryjny"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="filter.serial_number"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Data montażu"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <flat-pickr
              v-model="filter.installation_date"
              class="form-control"
              :config="{dateFormat: 'Y-m-d', locale: 'pl'}"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Rodzaje serwisów"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <select multiple v-model="filter.services" style="width: 100%;">
              <option v-for="service in services" :selected="filter.services.indexOf (service.id) != -1" :value="service.id">{{service.name}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Ilość dni o które service przedłuża gwarancję"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              type="number"
              v-model="filter.time_interval"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Data gwarancji"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <flat-pickr
              v-model="filter.guarantee"
              class="form-control"
              :config="{dateFormat: 'Y-m-d', locale: 'pl'}"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
        	<span style="display: inline-block; font-size: 2rem; color: #000; padding-bottom: 0.5rem; margin-bottom: 1rem; border-bottom: 2px solid #000; width: 100%;">Adres montażu</span>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Ulica"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="filter.address"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Kod pocztowy"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="filter.postcode"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Miasto"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="filter.city"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Województwo"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <select class="form-control" v-model="filter.state" style="width: 100%;">
              <option v-for="state in states" :value="state.name">{{state.name}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Przedstawiciel"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <select v-model="filter.representative_id" class="form-control" style="width: 100%;">
              <option v-for="representative in representatives" :selected="filter.representative_id === representative.id" :value="representative.id">{{representative.firstname}} {{representative.lastname}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="12" v-if="role_id == 1">
          <b-form-group
            label="Aktywny"
            label-for="blog-edit-slug"
            class="mb-2"
          >
		    <b-form-checkbox
              id="checkbox-1"
		      v-model="filter.active"
      		  name="checkbox-1"
		    >
		      Aktywny
		    </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mr-1"
          >
            Zapisz zmiany
          </b-button>
          <b-button
            :to="{ name: 'apps-client', params: { id: this.filter.client_id } }"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Anuluj
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Polish from 'flatpickr/dist/l10n/pl.js'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    flatPickr,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      role_id: localStorage.getItem('id'),
      filter: {
        id: 0,
        name: '',
        guarantee: '',
        installation_date: '',
        time_interval: 0,
        address: '',
        state: '',
        city: '',
        postcode: '',
        services: [],
        serial_number: '',
        representative_id: 0,
        client_id: 0,
        active: true
      },
      snowOption: {
        theme: 'snow',
      },
      services: [],
      representatives: [],
      states: [],
    }
  },
  created() {
    const id = this.$route.params.id ?? 0
    this.filter.client_id = this.$route.query.client_id ?? 0
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
    if (id > 0) {
      axios.get(`${process.env.VUE_APP_API_URL}Filter/${id}`, config).then(response => {
        this.filter = response.data
        var tempArray = []
        for(var i = 0; i < this.filter.services.length; i++) {
  		  tempArray.push(this.filter.services[i].id+"")
        }
        this.filter.services = tempArray
        this.filter.active = response.data.active == 1 ? true : false
      }).catch(error => {
        console.log(error)
      })
    }
	axios.get(`${process.env.VUE_APP_API_URL}Service`, config).then(response => {
	  this.services = response.data
	}).catch(error => {
	  console.log(error)
	})
    axios.get(`${process.env.VUE_APP_API_URL}Representative`, config).then(response => {
      this.representatives = response.data;
    }).catch(error => {
      console.log(error)
    })
	axios.get(`${process.env.VUE_APP_API_URL}State`, config).then(response => {
	  this.states = response.data
	}).catch(error => {
	  console.log(error)
	})
  },
  methods: {
    save() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } };
      axios.post(`${process.env.VUE_APP_API_URL}Filter`, this.filter, config).then(response => {
        if (this.filter.client_id > 0) {
          this.$router.push({ name: 'apps-client', params: { id: this.filter.client_id } })
        } else {
          this.$router.push({path: '/apps/filters'})
        }
      }).catch(error => {
      	var text = [];
      	for (const [key, value] of Object.entries(error.response.data.data)) {
		  if(value.length > 0) {
		  	text.push(value[0])
		  }
		}
        Swal.fire(
          text.join('<br/>'),
          '',
          'error',
        )
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
